import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import DataTable from "react-data-table-component"
import Dropdown from "react-dropdown"
import _ from "underscore"
import AutosuggestSearch from "../components/autosuggest-search"

export default ({ data }) => {

  const [year, setYear] = React.useState('2023');

  const tableData = data.allCheckbookCsv.group.map(function(o) {
    return {
      'organization': o.fieldValue,
      'amount': Math.round(o.nodes
                  .filter(z => z.FiscalYear === year)
                  .reduce( (a, b) => a + parseFloat(b.Amount), 0 ) )
    }
  })

  const totalSpending = tableData.map(x => x.amount).reduce((a, b) => a + b, 0);
  const totalTransactions = data.allCheckbookCsv.group
    .map(x => x.nodes.filter(o => o.FiscalYear === year).length)
    .reduce((a, b) => a + b, 0);
  const totalOrgs = tableData.filter(z => z.amount > 0).length;


  const vendorSearchData = [];

  // "Wrapper" function to apply in .map() below
  var getVendor = function(org) {
    return function(v) {
      return {
        'org': org,
        'name': v.Vendor,
        'title': '',
      }
    }
  }

  for (var i in data.allCheckbookCsv.group) {
    var org = data.allCheckbookCsv.group[i].fieldValue;

    var uniqueVendors = _.uniq(
      data.allCheckbookCsv.group[i].nodes.filter(node => node.FiscalYear === year),
      node => node.Vendor
    )

    vendorSearchData.push({
      fieldValue: org,
      nodes: uniqueVendors.map( getVendor(org) )
    });

  }


  return (
    <Layout>

      <div className="cf pv4">
        <h1 className="f1 fw3 ma0 fl">Checkbook</h1>

        <Dropdown
          options={['2019', '2020', '2021', '2022', '2023']}
          onChange={(v) => setYear(v.value) }
          className='mw4 fl mv2 ml4'
          placeholder={year}
          value={year}
        />

        <h4 className="fl f4 fw3 mv3 ml2">fiscal year</h4>
      </div>


      <p className="f4 pt2 pb3">
        This data allows citizens to view who received payments from the quasi-public
        organizations for goods or services and how much they received.
        The application allows the user to drill down from aggregated spending accounts
        all the way down to each individual payment to a payee.
        The values are rounded to the nearest dollar.
      </p>

      <div className="f4 w-100 w-third-l pa3 bg-near-white fl br bw5 b--white mb3">
        <h4 className="f4 mt0 mb2">Spending Summary</h4>

        <h5 className="f5 mb1">Total Spending</h5>
        <span className="pl2">${totalSpending.toLocaleString()}</span>

        <h5 className="f5 mb1">Total Transactions</h5>
        <span className="pl2">{totalTransactions.toLocaleString()}</span>

        <h5 className="f5 mb1">Total Organizations</h5>
        <span className="pl2">{totalOrgs}</span>

      </div>

      <div className="w-100 w-two-thirds-l fl pv3 ph2 ph0-l">

        <h4 className="f4 ma0">Search a Recipient/Vendor</h4>

        <div className="mt2 mb4">
          <AutosuggestSearch data={vendorSearchData} placeholder='Type vendor' />
        </div>

        <h4 className="f4 ma0">Spending by Organization</h4>

        <DataTable
          title="Total Expenses by Fiscal Year"
          keyField='organization'
          noHeader

          columns={[
            {
              name: 'Organization',
              selector: 'organization',
              sortable: true,
              grow: 5,
              format: x => <Link to={'/checkbook/' + x.organization} className="link black underline-hover">{x.organization}</Link>
            },
            {
              name: 'Expenses',
              selector: 'amount',
              sortable: true,
              right: true,
              format: x => x.amount ? ('$' + x.amount.toLocaleString() ) : 'No data'
            }
          ]}

          data={tableData}

          customStyles={{
            cells: {
              style: {
                paddingLeft: '0'
              }
            },
            headCells: {
              style: {
                fontSize: '13px',
                paddingLeft: '0',
                fontWeight: 'bold',
              }
            },
            rows: {
              style: {
                fontSize: '15px'
              }
            }
          }}
          />

      </div>

    </Layout>
  )

}


export const query = graphql`
  query Checkbook {
    allCheckbookCsv {
      group(field: Organization) {
        fieldValue
        nodes {
          FiscalYear
          Amount
          Vendor
        }
      }
    }
  }
`